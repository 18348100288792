import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedLibraryService, sharedLibraryConfigProvider } from 'projects/petszel-shared/src/public-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { appConfig } from '../staff.config';
import { PetszelSharedModule } from 'projects/petszel-shared/src/public-api';
import { AuthInterceptor } from './service/auth-interceptor-staff.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { setLicenseKey } from "survey-core";
import { CustomAdapter, CustomDateParserFormatter } from "./staff-date-picker-adapter";
import { StatsSummaryComponent } from './components/stats-summary/stats-summary.component';

setLicenseKey(
    "YzNkNDg3NTQtOGQ0Ny00Y2U1LWFkNzgtZTgzOTgzMmY5NTZjOzE9MjAyNS0wMy0xNCwyPTIwMjUtMDMtMTQsND0yMDI1LTAzLTE0"
);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    PetszelSharedModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG, // set log level
      serverLogLevel: NgxLoggerLevel.ERROR, // set server log level
      disableConsoleLogging: false //enable/disable console logging
    }),
    NgbModule
  ],
  providers: [
    {
      provide: 'appConfig',
      useValue: appConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    sharedLibraryConfigProvider,
    SharedLibraryService,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
