import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from './service/authentication-guard.service';
import { appConfig } from '../staff.config';
import {
  PetszelSharedModule,
  SharedLibraryService,
  sharedLibraryConfigProvider,
} from 'projects/petszel-shared/src/public-api';
import { PetszelLocalPartnerSearchComponent } from 'projects/petszel-shared/src/lib/petszel-local-partner-search/petszel-local-partner-search.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../app/home/home.module').then((m) => m.HomeModule),
    data: { bodyClass: 'gradient-staff' },
  },
  {
    path: '',
    loadChildren: () =>
      import('../app/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthenticationGuardService],
    data: { bodyClass: 'gradient-staff' },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), PetszelSharedModule],
  exports: [RouterModule],
  providers: [
    {
      provide: AuthenticationGuardService,
      useClass: AuthenticationGuardService
    },
    {
      provide: 'appConfig',
      useValue: appConfig,
    },
    sharedLibraryConfigProvider,
    SharedLibraryService,
  ],
})
export class AppRoutingModule {}
