import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationGuardService } from './authentication-guard.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationGuardService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if a token is available in local storage.
    const token = localStorage.getItem('token');

    if (token) {
      // Clone the request to add the token to the headers.
      const clonedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle the request and response.
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Token is invalid or expired; handle token refresh or logout.
            this.authService.logoutAndRedirect(); // Perform logout
            return throwError(error);
          }

          // Handle other HTTP errors here as needed.
          return throwError(error);
        })
      );
    } else {
      // No token found in local storage; proceed with the original request.
      return next.handle(request);
    }
  }
}
