import { staffEnvironment as betaEnvironment } from './environments/environment.beta';
import { staffEnvironment as devEnvironment } from './environments/environment.dev';
import { staffEnvironment as prodEnvironment } from './environments/environment.prod';
import { staffEnvironment as baseEnvironment } from './environments/environment';

export const appConfig = {
  beta: {
    apiUrl: betaEnvironment.apiUrl,
    beta: betaEnvironment.beta,
    color: betaEnvironment.bgNavColor
  },
  dev: {
    apiUrl: devEnvironment.apiUrl,
    development: devEnvironment.development,
    color: devEnvironment.bgNavColor
  },
  prod: {
    apiUrl: prodEnvironment.apiUrl,
    production: prodEnvironment.production,
    color: prodEnvironment.bgNavColor
  },
    apiUrl: baseEnvironment.apiUrl,
    color: baseEnvironment.bgNavColor
};
